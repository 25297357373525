import { ActionReducerMap } from '@ngrx/store';

import {
  entityReducer,
  paginationReducer,
} from './external-approved-rate-escalation-level.reducer';
import { IExternalApprovedRateEscalationLevelFeatureState } from './interfaces';

export const reducers: ActionReducerMap<IExternalApprovedRateEscalationLevelFeatureState> = {
  entityState: entityReducer,
  pagination: paginationReducer,
};
