import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Boxed, FormControlState, FormGroupState } from 'ngrx-forms';
import { combineLatest, map, mergeMap, Observable, of, switchMap } from 'rxjs';

import { TabId } from '@locumsnest/components/src';
import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { IMessage } from '@locumsnest/components/src/lib/interfaces/message';
import { IAlertState, ICloneDocumentToHospitalFormState } from '@locumsnest/core/src';
import { IContactFormState } from '@locumsnest/core/src/lib/adapters/contact-form-adapter';
import { INoteComponentState } from '@locumsnest/core/src/lib/adapters/note-component-adapter';
import { getScrollDetails, ScrollDetails } from '@locumsnest/core/src/lib/helpers/pageScroll';
import { Time } from '@locumsnest/core/src/lib/helpers/time';
import { HospitalProfessionConfigurationService } from '@locumsnest/hospital-profession-configuration/src';
import { IEmploymentHistory } from '@locumsnest/profile-ui/src/interfaces/employment-history-entity';

import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { AUTH_GROUPS } from '../../../auth-group/constants';
import { CertificateTypeService } from '../../../certificate-type/+state/certificate-type.service';
import { RouterService } from '../../../core/services/';
import { ExternalStaffingCandidateBidStatusService } from '../../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import { ExternalStaffingCandidateBidService } from '../../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { HospitalProfileLabelService } from '../../../hospital-profile-label/+state/hospital-profile-label.service';
import { HospitalProfileTagService } from '../../../hospital-profile-tag/+state/hospital-profile-tag.service';
import { HospitalStaffBankUnavailabilityReasonService } from '../../../hospital-staff-bank-unavailability-reason/+state/hospital-staff-bank-unavailability-reason.service';
import { HospitalStaffBankUnavailabilityService } from '../../../hospital-staff-bank-unavailability/+state/hospital-staff-bank-unavailability.service';
import { IStaffBankUnavailabilitiesFormState } from '../../../hospital-staff-bank-unavailability/+state/interfaces/staff-bank-unavailabilities-form-state';
import { selectStaffBankUnavailabilitiesFormState } from '../../../hospital-staff-bank-unavailability/+state/staff-bank-unavailabilities-form/staff-bank-unavailabilities-form.selector';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { IHospitalStaffBankUnavailabilityEntity } from '../../../interfaces/api/hospital-staff-bank-unavailability-entity';
import { IProfileNote } from '../../../interfaces/api/profile-note-entity';
import { PermissionService } from '../../../permission/+state/permission.service';
import { StaffBankCollaborationGroupService } from '../../../staff-bank-collaboration-group/+state/staff-bank-collaboration-group.service';
import { StaffBankMembershipService } from '../../../staff-bank-membership/+state/staff-bank-membership.service';
import { selectProfileLabels, selectProfileTags } from '../../+state/form/form.selectors';
import {
  AddOneProfileLabelSignal,
  AddOneProfileTagSignal,
  InitializeProfileFormSignal,
  RemoveOneProfileLabelSignal,
  RemoveOneProfileTagSignal,
} from '../../+state/form/form.signals';
import { IProfilePersonalInfo } from '../../+state/interfaces';
import {
  DocumentTypes,
  EmploymentHistoryFilterButton,
  EmploymentHistoryFilterTypes,
  IPassportDocument,
  IProfileAssignmentNumberEntry,
  IProfileRegistryRecord,
  ISafeboxDocument,
  IUploadFileFormState,
  ProfileAssignmentNumbersFilterTypes,
  ProfileDocument,
  ProfileUiTabs,
} from '../../+state/interfaces/profile-ui-state';
import { IUpdateExpirationDate } from '../../+state/interfaces/update-expiration-date-payload';
import { selectPersonalInformation } from '../../+state/profile.selectors';
import { ProfileService } from '../../+state/profile.service';
import {
  selectComplianceMessageFormState,
  selectEmploymentHistoryFilterUiState,
  selectNoteFormState,
  selectProfileAlertToDisplay,
  selectProfileAssignmentNumbersFilterUiState,
  selectSelectedTab,
  selectShowFlaggedNotesUiState,
  selectUploadFileFormState,
  selectUploadFileType,
} from '../../+state/ui/ui.selectors';
import {
  CloneCertificateToHospitalSignal,
  CloneReferenceToHospitalSignal,
  CopyCertificateToSafeboxSignal,
  CopyReferenceToSafeboxSignal,
  DeleteProfileNoteForSelectedProfileSignal,
  DeleteSafeboxCertificateSignal,
  DeleteSafeboxReferenceSignal,
  InitializeCloneDocumentToHospitalSignal,
  InitializeProfileUiSignal,
  LoadMoreComplianceMessagesUiSignal,
  LoadMoreEmploymentHistoryUiSignal,
  LoadMoreProfileNotesUiSignal,
  MarkProfileNoteAsFlaggedSignal,
  MarkProfileNoteAsUnFlaggedSignal,
  RemoveFileSignal,
  ResetAlertSignal,
  SelectTabSignal,
  SendComplianceMessageUiSignal,
  SetEmploymentHistoryFilterUiSignal,
  SetProfileAssignmentNumbersFilterUiSignal,
  SubmitNewProfileNoteForSelectedProfileSignal,
  ToggleFlaggedProfileNotesSignal,
  UpdateCertificateExpirationDateSignal,
  UpdateReferenceExpirationDateSignal,
  UploadFileSignal,
} from '../../+state/ui/ui.signals';
import { ProfileDetailPageService } from './profile-detail-page.service';

enum ShareDocumentCategory {
  certificates = 'Certificates',
  references = 'References',
}

@Component({
  selector: 'locumsnest-profile-detail-page',
  templateUrl: './profile-detail-page.component.html',
  styleUrls: ['./profile-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDetailPageComponent implements OnInit {
  static namespace = 'ProfileDetailPage';
  @Input() contactForm: FormGroupState<IContactFormState>;

  public contactForm$: Observable<FormGroupState<IContactFormState>>;
  public noteFormState$: Observable<FormGroupState<INoteComponentState>>;
  public subjectOptions$: Observable<ISelectOption[]>;
  public messages$: Observable<IMessage[]>;
  public profile$: Observable<IProfileRegistryRecord>;
  public personalInformation$: Observable<IProfilePersonalInfo>;
  public gmcStatus$: Observable<string>;
  public passportDocs$: Observable<IPassportDocument[]>;
  public staffBankMemberShips$: Observable<string>;
  public staffBankCollaborationGroups$: Observable<string>;
  public agencyMemberships$: Observable<string>;
  public employmentHistory$: Observable<IEmploymentHistory[]>;
  public employmentHistoryFilter$: Observable<EmploymentHistoryFilterTypes>;
  public profileNotes$: Observable<IProfileNote[]>;
  public certificateDocs$: Observable<ISafeboxDocument[]>;
  public referenceDocs$: Observable<ISafeboxDocument[]>;
  public uploadFileForm$: Observable<FormGroupState<IUploadFileFormState>>;
  public isTitleVisible$: Observable<boolean>;
  public hasAddSafeboxCertificatePermission$: Observable<boolean>;
  public hasHospitalProfessionConfiguration$: Observable<boolean>;
  public hasChangeSafeboxCertificatePermission$: Observable<boolean>;
  public hasDeleteSafeboxCertificatePermission$: Observable<boolean>;
  public alertToDisplay$: Observable<IAlertState>;
  public scrollDetails$: Observable<ScrollDetails>;
  public selectedTab$: Observable<string>;
  public profileLabels$: Observable<FormControlState<Boxed<number[]>>>;
  public profileTags$: Observable<FormControlState<Boxed<number[]>>>;
  public workedHours$: Observable<number>;
  public partOfBank$: Observable<boolean>;
  public memberOfCollaboration$: Observable<boolean>;
  public licensed$: Observable<boolean>;
  public workedForOrganization$: Observable<boolean>;
  public workedForDepartment$: Observable<boolean>;
  public hasEmploymentHistory$: Observable<boolean>;
  public profileOnVisaRestrictions$: Observable<boolean>;
  public unavailabilityForm$: Observable<FormGroupState<IStaffBankUnavailabilitiesFormState>>;
  public unavailabilityReasonOptions$: Observable<ISelectOption[]>;
  public unavailabilities$: Observable<IHospitalStaffBankUnavailabilityEntity[]>;
  public showFlaggedNotes$: Observable<boolean>;
  public shareProfileUrl$: Observable<string>;
  public agencyDocs$: Observable<IPassportDocument[]>;
  public generalTab = ProfileUiTabs.general;
  public safeboxTab = ProfileUiTabs.digitalSafebox;
  public passportTab = ProfileUiTabs.digitalPassport;
  public sendMessageTab = ProfileUiTabs.sendMessage;
  public unavailabilityTab = ProfileUiTabs.unavailability;
  public agencyDocumentsTab = ProfileUiTabs.agencyDocuments;
  public hospitalProfileTagOptions$: Observable<ISelectOption[]>;
  public hospitalProfileLabelOptions$: Observable<ISelectOption[]>;
  public disableIfNotTagsManager$: Observable<boolean>;
  public disableIfNotLabelsManager$: Observable<boolean>;
  public profileAssignmentNumberEntries$: Observable<IProfileAssignmentNumberEntry[]>;
  public profileAssignmentNumbersFilter$: Observable<ProfileAssignmentNumbersFilterTypes>;

  public safeboxCertificateOptions$: Observable<ISelectOption[]>;
  public safeboxReferenceOptions$: Observable<ISelectOption[]>;
  public staffBankCollaborationGroupTrustOptions$: Observable<ISelectOption[]>;

  public cloneDocumentToHospitalFormState$: Observable<
    FormGroupState<ICloneDocumentToHospitalFormState>
  >;
  public hasAddSafeboxReferencePermission$: Observable<boolean>;

  public showShareDocumentsModal = false;
  public shareDocumentCategory: ShareDocumentCategory;

  employmentHistoryFilterButtons: EmploymentHistoryFilterButton[] = [
    {
      title: `Locum's Nest Shifts`,
      filter: EmploymentHistoryFilterTypes.application,
    },
    { title: `Employment history`, filter: EmploymentHistoryFilterTypes.job },
    { title: `Bids`, filter: EmploymentHistoryFilterTypes.bids },
  ];

  constructor(
    private store: Store,
    private certificateTypeService: CertificateTypeService,
    private profileService: ProfileService,
    private staffBankMembershipService: StaffBankMembershipService,
    private hospitalService: HospitalService,
    private permissionService: PermissionService,
    private hospStaffBankUnavailabilityReasonService: HospitalStaffBankUnavailabilityReasonService,
    private hospitalStaffBankUnavailabilityService: HospitalStaffBankUnavailabilityService,
    private routerService: RouterService,
    private externalStaffingCandidateBidService: ExternalStaffingCandidateBidService,
    private externalStaffingProviderService: ExternalStaffingProviderService,
    private externalStaffingBidStatusService: ExternalStaffingCandidateBidStatusService,
    private hospitalProfileLabelService: HospitalProfileLabelService,
    private hospitalProfileTagService: HospitalProfileTagService,
    private authGroupService: AuthGroupService,
    private staffBankCollaborationGroupService: StaffBankCollaborationGroupService,
    private hospitalProfessionConfigurationService: HospitalProfessionConfigurationService,
    private profileDetailPageService: ProfileDetailPageService,
  ) {
    this.agencyDocs$ = this.profileService.getSelectedExternalStaffingCandidateDocuments();
    this.contactForm$ = this.store.pipe(select(selectComplianceMessageFormState));
    this.noteFormState$ = this.store.pipe(select(selectNoteFormState));
    this.selectedTab$ = this.store.pipe(select(selectSelectedTab));
    this.profileLabels$ = this.store.pipe(select(selectProfileLabels));
    this.profileTags$ = this.store.pipe(select(selectProfileTags));
    this.shareProfileUrl$ = this.routerService.getShareProfileUrl();
    this.uploadFileForm$ = this.store.pipe(select(selectUploadFileFormState));
    this.hospitalProfileTagOptions$ = this.hospitalProfileTagService.getHospitalProfileTagOptions();
    this.hospitalProfileLabelOptions$ =
      this.hospitalProfileLabelService.getHospitalProfileLabelOptions();

    this.isTitleVisible$ = this.store.pipe(
      select(selectUploadFileType),
      mergeMap((selectedFileTypeId) => this.certificateTypeService.getById(selectedFileTypeId)),
      map((selectedCertificateType) => selectedCertificateType?.slug === 'other'),
    );

    this.employmentHistoryFilter$ = this.store.pipe(select(selectEmploymentHistoryFilterUiState));

    this.profileAssignmentNumbersFilter$ = this.store.pipe(
      select(selectProfileAssignmentNumbersFilterUiState),
    );

    this.subjectOptions$ = this.certificateTypeService.getCertificateTypeOptions();
    this.hasAddSafeboxCertificatePermission$ =
      this.permissionService.hasCurrentPermission('add_safeboxcertificate');
    this.hasChangeSafeboxCertificatePermission$ = this.permissionService.hasCurrentPermission(
      'change_safeboxcertificate',
    );
    this.hasDeleteSafeboxCertificatePermission$ = this.permissionService.hasCurrentPermission(
      'delete_safeboxcertificate',
    );

    this.hasHospitalProfessionConfiguration$ = this.profileService
      .getSelectedProfile()
      .pipe(
        mergeMap((profile) =>
          this.hospitalProfessionConfigurationService
            .getConfigurationByProfessionId(profile.profession)
            .pipe(
              map(
                (hospitalProfessionConfiguration) =>
                  hospitalProfessionConfiguration?.esrIntegration,
              ),
            ),
        ),
      );

    this.messages$ = this.profileService.getSelectedProfileMessages();
    this.profile$ = this.profileService.getSelectedProfileRegistryRecord();
    this.personalInformation$ = this.store.pipe(select(selectPersonalInformation));
    this.gmcStatus$ = this.profileService.getSelectedProfileGmcStatus();
    this.passportDocs$ = this.profileService.getSelectedProfilePassportCertificates();
    this.certificateDocs$ = this.profileService.getSelectedProfileSafeBoxCertificates();
    this.referenceDocs$ = this.profileService.getSelectedProfileSafeBoxReferences();
    this.safeboxCertificateOptions$ =
      this.profileService.getSelectedProfileSafeBoxCertificateOptions();
    this.safeboxReferenceOptions$ = this.profileService.getSelectedProfileSafeBoxReferenceOptions();

    this.staffBankMemberShips$ = this.profileService.getStaffBankMembershipsDisplay();

    this.staffBankCollaborationGroups$ =
      this.profileService.getStaffBankCollaborationGroupDisplay();

    this.agencyMemberships$ = combineLatest([
      this.profileService
        .getSelectedProfileId()
        .pipe(
          switchMap((profile) =>
            this.externalStaffingCandidateBidService.getAllForProfile(profile),
          ),
        ),
      this.externalStaffingBidStatusService.getExternalStaffingCandidateBidStatusCompletedIds(),
    ]).pipe(
      switchMap(([bids, statuses]) => {
        if (bids.length) {
          const lastCompletedBid = bids
            .sort((a, b) => +b.startTime - +a.startTime)
            .find((bid) => statuses.includes(bid.status));
          return this.externalStaffingProviderService
            .getOne(lastCompletedBid?.staffingProvider)
            .pipe(
              map(
                (staffingProvider) =>
                  `${staffingProvider.name} ${
                    lastCompletedBid.startTime
                      ? `| last used: ${Time.formatDate(lastCompletedBid.startTime)}`
                      : ''
                  } `,
              ),
            );
        }
        return of(null);
      }),
    );

    this.employmentHistory$ = this.profileDetailPageService.getSelectedProfileEmploymentHistory(
      ProfileDetailPageComponent.namespace,
    );
    this.profileAssignmentNumberEntries$ =
      this.profileService.getSelectedProfileAssignmentNumbers();
    this.alertToDisplay$ = this.store.pipe(select(selectProfileAlertToDisplay));
    this.profileNotes$ = this.profileService.getSelectedProfileNotes();
    this.licensed$ = this.profileService.getSelectedProfileLicensed();
    this.workedHours$ = this.profileService.getSelectedProfileWorkedHours();
    this.workedForOrganization$ = this.profileService.getSelectedProfileWorkedForOrganization();
    this.workedForDepartment$ = this.profileService.getSelectedProfileWorkedForDepartment();
    this.hasEmploymentHistory$ = this.profileService.getSelectedProfileHasEmploymentHistory();

    this.disableIfNotTagsManager$ = this.authGroupService
      .getOfficerBelongsToAuthGroup(AUTH_GROUPS.PROFILES_TAGS_MANAGER)
      .pipe(map((hasPermission) => !hasPermission));

    this.disableIfNotLabelsManager$ = this.authGroupService
      .getOfficerBelongsToAuthGroup(AUTH_GROUPS.PROFILES_LABELS_MANAGER)
      .pipe(map((hasPermission) => !hasPermission));

    this.partOfBank$ = combineLatest([
      this.profileService.getSelectedProfileId(),
      this.hospitalService.getAssigned(),
    ]).pipe(
      mergeMap(([profileId, assignedHospital]) =>
        this.staffBankMembershipService.isPartOfOurBank(profileId, assignedHospital),
      ),
    );

    this.memberOfCollaboration$ = this.profileService.isMemberOfCollaboration();

    this.profileOnVisaRestrictions$ = combineLatest([
      this.profileService.getSelectedProfileId(),
      this.hospitalService.getAssigned(),
    ]).pipe(
      mergeMap(([profileId, assignedHospital]) =>
        this.staffBankMembershipService.isProfileOnVisaRestrictions(profileId, assignedHospital),
      ),
    );

    this.unavailabilityForm$ = this.store.pipe(select(selectStaffBankUnavailabilitiesFormState));
    this.unavailabilityReasonOptions$ =
      this.hospStaffBankUnavailabilityReasonService.getHospitalStaffBankUnavailabilityReasonOptions();
    this.unavailabilities$ = this.hospitalStaffBankUnavailabilityService.getAll();

    this.showFlaggedNotes$ = this.store.pipe(select(selectShowFlaggedNotesUiState));

    this.staffBankCollaborationGroupTrustOptions$ =
      this.staffBankCollaborationGroupService.getStaffBankCollaborationGroupTrustOptions();
    this.cloneDocumentToHospitalFormState$ =
      this.profileService.getCloneDocumentToHospitalFormState();

    this.hasAddSafeboxReferencePermission$ =
      this.permissionService.hasCurrentPermission('add_safeboxreference');
  }

  ngOnInit() {
    this.scrollDetails$ = getScrollDetails();
    this.store.dispatch(
      new InitializeProfileUiSignal({ namespace: ProfileDetailPageComponent.namespace }),
    );
    this.store.dispatch(new InitializeProfileFormSignal({}));
  }

  public sendComplianceMessage() {
    this.store.dispatch(new SendComplianceMessageUiSignal({}));
  }

  public onLoadMoreEmploymentHistory() {
    this.store.dispatch(
      new LoadMoreEmploymentHistoryUiSignal({ namespace: ProfileDetailPageComponent.namespace }),
    );
  }

  public onLoadMoreProfileNotes() {
    this.store.dispatch(
      new LoadMoreProfileNotesUiSignal({ namespace: ProfileDetailPageComponent.namespace }),
    );
  }

  public onLoadMoreComplianceMessages() {
    this.store.dispatch(
      new LoadMoreComplianceMessagesUiSignal({ namespace: ProfileDetailPageComponent.namespace }),
    );
  }

  public onUpdateEmploymentHistoryFilter(payload) {
    this.store.dispatch(new SetEmploymentHistoryFilterUiSignal(payload));
  }

  public onUpdateProfileAssignmentNumbersFilter(payload) {
    this.store.dispatch(new SetProfileAssignmentNumbersFilterUiSignal(payload));
  }

  public onResetUploadFileForm() {
    this.store.dispatch(new RemoveFileSignal());
  }

  public onConfirmUploadFile() {
    this.store.dispatch(new UploadFileSignal());
  }

  public onRenewExpirationDate(payload: IUpdateExpirationDate) {
    if (payload.document.type === DocumentTypes.certificate) {
      this.store.dispatch(new UpdateCertificateExpirationDateSignal({ payload }));
    } else if (payload.document.type === DocumentTypes.reference) {
      this.store.dispatch(new UpdateReferenceExpirationDateSignal({ payload }));
    }
  }

  public onDeleteDocument(document: ProfileDocument) {
    if (document.type === DocumentTypes.certificate) {
      this.store.dispatch(new DeleteSafeboxCertificateSignal({ document }));
    } else if (document.type === DocumentTypes.reference) {
      this.store.dispatch(new DeleteSafeboxReferenceSignal({ document }));
    }
  }

  public onCopyToSafebox(document: ProfileDocument) {
    if (document.type === DocumentTypes.certificate) {
      this.store.dispatch(new CopyCertificateToSafeboxSignal({ document }));
    } else if (document.type === DocumentTypes.reference) {
      this.store.dispatch(new CopyReferenceToSafeboxSignal({ document }));
    }
  }

  public onDeleteNote(note: IProfileNote) {
    this.store.dispatch(
      new DeleteProfileNoteForSelectedProfileSignal({
        note,
        namespace: ProfileDetailPageComponent.namespace,
      }),
    );
  }

  public onFlagNote(note: IProfileNote) {
    if (!note.flagged) {
      this.store.dispatch(new MarkProfileNoteAsFlaggedSignal({ profileNoteId: note.id }));
    } else {
      this.store.dispatch(new MarkProfileNoteAsUnFlaggedSignal({ profileNoteId: note.id }));
    }
  }

  public onToggleFlaggedNotes(_: Event) {
    this.store.dispatch(
      new ToggleFlaggedProfileNotesSignal({
        namespace: ProfileDetailPageComponent.namespace,
      }),
    );
  }

  public onSubmitNewNote(note: string) {
    this.store.dispatch(
      new SubmitNewProfileNoteForSelectedProfileSignal({
        note,
        namespace: ProfileDetailPageComponent.namespace,
      }),
    );
  }

  public onSelectTab(tabId: TabId) {
    this.store.dispatch(new SelectTabSignal({ tab: tabId.toString() }));
  }

  public onAlertToastClicked() {
    this.store.dispatch(new ResetAlertSignal({}));
  }

  public onSelectProfileTag(id: number | string, action: 'addOne' | 'removeOne') {
    if (action === 'addOne') this.store.dispatch(new AddOneProfileTagSignal({ id: +id }));
    if (action === 'removeOne') this.store.dispatch(new RemoveOneProfileTagSignal({ id: +id }));
  }

  public onSelectProfileLabel(id: number | string, action: 'addOne' | 'removeOne') {
    if (action === 'addOne') this.store.dispatch(new AddOneProfileLabelSignal({ id: +id }));
    if (action === 'removeOne') this.store.dispatch(new RemoveOneProfileLabelSignal({ id: +id }));
  }

  public onShareDocuments(category: ShareDocumentCategory) {
    this.showShareDocumentsModal = true;
    this.shareDocumentCategory = category;
    this.store.dispatch(new InitializeCloneDocumentToHospitalSignal({}));
  }

  public closeShareDocumentsModal() {
    this.showShareDocumentsModal = false;
  }

  public getModalTitle(firstName: string, shareDocumentCategory: ShareDocumentCategory) {
    return (
      'Share ' +
      firstName +
      '’s ' +
      (shareDocumentCategory === ShareDocumentCategory.certificates
        ? ShareDocumentCategory.certificates
        : ShareDocumentCategory.references)
    );
  }

  public onShareButtonClicked(shareDocumentCategory: ShareDocumentCategory) {
    if (shareDocumentCategory === ShareDocumentCategory.references) {
      this.store.dispatch(new CloneReferenceToHospitalSignal({}));
    } else if (shareDocumentCategory === ShareDocumentCategory.certificates) {
      this.store.dispatch(new CloneCertificateToHospitalSignal({}));
    }
  }
}
