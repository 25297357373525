import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers } from './+state';
import { featureKey } from './+state/demand-request.reducer';
import { DemandRequestFormEffects } from './+state/form/form.effects';

export const demandRequestProviders: EnvironmentProviders[] = [
  importProvidersFrom([
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(DemandRequestFormEffects),
  ]),
];
