import { createSelector } from '@ngrx/store';

import { selectDemandRequestState } from '../demand-request.selectors';

export const selectDemandControlFormState = createSelector(
  selectDemandRequestState,
  (state) => state.formState,
);

export const selectAuthoriserNoteControl = createSelector(
  selectDemandControlFormState,
  (form) => form.controls.authoriserNote,
);
