<div>
  <div class="title" [class.inactive]="!canReject()">{{ stepTitle() }}</div>
  <div class="name" [class.inactive]="!canReject()">{{ name() }}</div>
</div>

@if (canReject()) {
  @if (showConfirmation().approve || showConfirmation().reject) {
    <locumsnest-text-area
      invertColors
      rows="6"
      style="display: block"
      [placeholder]="notesPlaceholder()"
      [ngrxFormControlState]="authoriserNoteControl()"
    />

    <div class="buttons-container">
      <locumsnest-button
        fullWidth
        buttonType="accept"
        size="medium"
        [marginRight]="false"
        (click)="onConfirm()"
      >
        CONFIRM
      </locumsnest-button>

      <locumsnest-button
        fullWidth
        buttonType="alert"
        size="medium"
        [marginRight]="false"
        (click)="onCancel()"
      >
        CANCEL
      </locumsnest-button>
    </div>
  } @else {
    <div class="buttons-container">
      <span [tp]="canAuthorise() ? null : 'You are not authorised to approve.'">
        <locumsnest-button
          fullWidth
          buttonType="accept"
          size="medium"
          [disabled]="!canAuthorise()"
          [marginRight]="false"
          (click)="onShowConfirmation('approve')"
        >
          AUTHORISE
        </locumsnest-button>
      </span>
      <locumsnest-button
        fullWidth
        buttonType="alert"
        size="medium"
        [marginRight]="false"
        (click)="onShowConfirmation('reject')"
      >
        REJECT
      </locumsnest-button>
    </div>
  }
}
