<div
  #container
  [@expanded]="{
    value: expanded(),
    params: {
      startHeight: container.clientHeight
    }
  }"
>
  <div class="approved-container" [class.has-rejection]="!expanded() && rejectedDate()">
    <locumsnest-icon-button (buttonClicked)="toggleExpanded()">
      <div
        class="chevron"
        [class.expanded]="expanded()"
        [class.has-rejection]="!expanded() && rejectedDate()"
      ></div>
    </locumsnest-icon-button>

    <div style="display: flex; flex-direction: column; gap: 5px">
      @if (expanded()) {
        <ln-demand-control-approved-step
          stepTitle="Shift created"
          [isActive]="false"
          [date]="shiftCreatedDate()"
          [name]="requestedBy()"
          [expanded]="expanded()"
        />
      }

      @if (expanded() || (!primaryAuthorisedDate() && !rejectedDate())) {
        <ln-demand-control-approved-step
          stepTitle="Demand requested"
          [isActive]="demandRequestActive()"
          [date]="requestedDate()"
          [name]="requestedBy()"
          [note]="requestNote()"
          [vacancyReason]="vacancyReason()"
          [expanded]="expanded()"
        />
      }

      @if (
        primaryAuthorisedDate() &&
        (expanded() || (!enhancedAuthorisedDate() && !rejectedDate() && !shiftReleasedDate()))
      ) {
        <ln-demand-control-approved-step
          stepTitle="Demand approved (primary)"
          [isActive]="primaryAuthoriseActive()"
          [date]="primaryAuthorisedDate()"
          [name]="primaryAuthorisedBy()"
          [expanded]="expanded()"
          [note]="primaryAuthoriserNote()"
        />
      }

      @if (enhancedAuthorisedDate() && expanded()) {
        <ln-demand-control-approved-step
          stepTitle="Demand approved (enhanced)"
          [isActive]="false"
          [date]="enhancedAuthorisedDate()"
          [name]="enhancedAuthorisedBy()"
          [expanded]="expanded()"
          [note]="enhancedAuthoriserNote()"
        />
      }
    </div>

    @if (shiftReleasedDate()) {
      <ln-demand-control-approved-step
        stepTitle="Shift released to bank"
        [isActive]="true"
        [date]="shiftReleasedDate()"
        [name]="shiftReleasedBy()"
        [expanded]="expanded()"
      />
    }
  </div>

  @if (!rejectedDate() && !shiftReleasedDate() && expanded()) {
    <div class="pending-container">
      @if (!primaryAuthorisedDate()) {
        <ln-demand-control-pending-step
          stepTitle="Primary demand review"
          [name]="primaryAuthorisedBy()"
          [canAuthorise]="canAuthorisePrimary()"
          [canReject]="canRejectPrimary()"
          [authoriserNoteControl]="primaryAuthoriserNoteControl()"
          (approve)="onApprove()"
          (reject)="onReject()"
        />
      }
      @if (!enhancedAuthorisedDate() && enhancedAuthorisedBy()) {
        <ln-demand-control-pending-step
          stepTitle="Enhanced demand review"
          [name]="enhancedAuthorisedBy()"
          [canAuthorise]="!!primaryAuthorisedDate() && canAuthoriseEnhanced()"
          [canReject]="!!primaryAuthorisedDate() && canRejectEnhanced()"
          [authoriserNoteControl]="primaryAuthoriserNoteControl()"
          (approve)="onApprove()"
          (reject)="onReject()"
        />
      }

      @if (!shiftReleasedDate()) {
        <ln-demand-control-pending-step
          stepTitle="Shift released to bank"
          [name]="enhancedAuthorisedBy() || primaryAuthorisedBy()"
          [authoriserNoteControl]="primaryAuthoriserNoteControl()"
        />
      }
    </div>
  }
</div>

@if (rejectedDate()) {
  <div class="pending-container">
    <ln-demand-control-approved-step
      stepTitle="Shift demand rejected"
      [isActive]="true"
      [date]="rejectedDate()"
      [name]="rejectedBy()"
      [expanded]="expanded()"
      [rejected]="true"
      [note]="rejectionReason()"
    />
  </div>
}
