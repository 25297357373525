@if (isActive() && expanded() && !rejected()) {
  <div class="next-shape">
    <div class="next-shape__line"></div>
    <div class="next-shape__oval"></div>
  </div>
}

<div class="title" [class.active]="isActive()" [class.inactive]="!isActive()">
  {{ stepTitle() }}
</div>
<div class="name" [class.active]="isActive()" [class.inactive]="!isActive()">
  by {{ name() }}, {{ date() }}
</div>

@if (vacancyReason()) {
  <div class="reason mt" [class.inactive]="!isActive()">Reason: {{ vacancyReason() }}</div>
}
@if (note()) {
  <div class="reason" [class.inactive]="!isActive()">Note: {{ note() }}</div>
}
