import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './external-approved-rate-escalation-level.adapter';
import { featureKey } from './external-approved-rate-escalation-level.reducer';
import { IExternalApprovedRateEscalationLevelFeatureState } from './interfaces';

const selectExternalApprovedRateEscalationLevelState =
  createFeatureSelector<IExternalApprovedRateEscalationLevelFeatureState>(featureKey);
export const selectExternalApprovedRateEscalationLevelEntityState = createSelector(
  selectExternalApprovedRateEscalationLevelState,
  (state) => state.entityState,
);
export const externalApprovedRateEscalationLevelPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectExternalApprovedRateEscalationLevelState,
    null,
    'pagination',
    'entityState',
    false,
  );
