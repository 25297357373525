import { ActionReducerMap } from '@ngrx/store';

import { entityReducer, paginationReducer } from './demand-request.reducer';
import { reducer } from './form/form.reducer';
import { IDemandRequestFeatureState } from './interfaces';

export const reducers: ActionReducerMap<IDemandRequestFeatureState> = {
  entityState: entityReducer,
  pagination: paginationReducer,
  formState: reducer,
};
