import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'ln-demand-control-approved-step',
  standalone: true,
  templateUrl: './demand-control-approved-step.component.html',
  styleUrl: './demand-control-approved-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.rejected]': 'rejected()',
  },
})
export class DemandControlApprovedStepComponent {
  isActive = input(false);
  vacancyReason = input<string>();
  note = input<string>();
  name = input.required<string>();
  date = input.required<string>();
  stepTitle = input.required<string>();
  expanded = input.required<boolean>();
  rejected = input(false);
}
