import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './demand-request.adapter';
import { featureKey } from './demand-request.reducer';
import { IDemandRequestFeatureState } from './interfaces';

export const selectDemandRequestState =
  createFeatureSelector<IDemandRequestFeatureState>(featureKey);

export const selectDemandRequestEntityState = createSelector(
  selectDemandRequestState,
  (state) => state.entityState,
);
export const demandRequestPaginationSelectors = paginationAdapter.paginationSelectors(
  selectDemandRequestState,
  null,
  'pagination',
  'entityState',
  false,
);
