import { inject, Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, merge, of, switchMap } from 'rxjs';

import { GLOBAL_ALERT_HANDLER, ofSignalType } from '@locumsnest/core/src';

import { DemandRequestService } from '../demand-request.service';
import {
  InitializeDemandControlFormMessage,
  LoadJobListingAfterDemandActionMessage,
} from './form.messages';
import { ApproveDemandRequest, RejectDemandRequest } from './form.signals';

@Injectable()
export class DemandRequestFormEffects {
  private actions$ = inject(Actions);
  private demandRequestService = inject(DemandRequestService);
  private alertHandlerService = inject(GLOBAL_ALERT_HANDLER);

  approveDemandRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ApproveDemandRequest),
      exhaustMap(({ payload }) => {
        const notesControl = this.demandRequestService.getAuthoriserNoteControl();
        const note = notesControl().value;
        return this.demandRequestService.approveDemandRequest(payload.id, note).pipe(
          switchMap((res) => {
            const actions: Actions[] = [of(res), of(new InitializeDemandControlFormMessage({}))];
            if (res.payload.entity.status === 'APPROVED') {
              actions.push(
                of(
                  new LoadJobListingAfterDemandActionMessage({ id: res.payload.entity.jobListing }),
                ),
              );
            }
            return merge(...actions);
          }),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while authorising : ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! An error occurred while authorising. ` + `The error was: ${message}`,
              unknownErrorMessage: 'Sorry! Unable to authorise. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  rejectDemandRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(RejectDemandRequest),
      exhaustMap(({ payload }) => {
        const notesControl = this.demandRequestService.getAuthoriserNoteControl();
        const note = notesControl().value;
        if (!notesControl().value?.trim())
          return this.alertHandlerService.handleAlert({
            message: 'Please provide a rejection note. This field cannot be left blank.',
            type: 'error',
          });
        return this.demandRequestService.rejectDemandRequest(payload.id, note).pipe(
          switchMap((action) =>
            merge(
              of(action),
              of(new InitializeDemandControlFormMessage({})),
              of(
                new LoadJobListingAfterDemandActionMessage({
                  id: action.payload.entity.jobListing,
                }),
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while rejecting : ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! An error occurred while rejecting. ` + `The error was: ${message}`,
              unknownErrorMessage: 'Sorry! Unable to reject. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );
}
