import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { LISTING_INDEX } from './demand-request.adapter';
import {
  paginationMessages,
  ResetDemandRequestPaginationMessage,
  UpsertDemandRequestPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './demand-request.messages';
import { DemandRequestPersistenceService } from './demand-request.persistence.service';
import {
  demandRequestPaginationSelectors,
  selectDemandRequestEntityState,
} from './demand-request.selectors';
import { selectAuthoriserNoteControl } from './form/form.selectors';
import { IDemandRequestEntity } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class DemandRequestService extends PaginatedStateService<
  IDemandRequestEntity,
  UpsertDemandRequestPageMessage,
  ResetDemandRequestPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(DemandRequestPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return demandRequestPaginationSelectors;
  }

  get entityStateSelector() {
    return selectDemandRequestEntityState;
  }

  fetch() {
    return this.initializePagination('default', {});
  }

  loadByListingIds(ids: number[]) {
    return this.persistenceService
      .retrieve({ listings: ids })
      .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
  }

  getByListingId(id: number) {
    return this.getOneByUniqueIndex(LISTING_INDEX, id);
  }

  getOneOrNullByListingId(id: number) {
    return this.getOneOrNoneByUniqueIndex(LISTING_INDEX, id);
  }

  getAuthoriserNoteControl() {
    return this.store.selectSignal(selectAuthoriserNoteControl);
  }

  approveDemandRequest(id: number, note: string) {
    return this.persistenceService
      .approveDemandRequest(id, note)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  rejectDemandRequest(id: number, note: string) {
    return this.persistenceService
      .rejectDemandRequest(id, note)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  getAuthorisersByDemandRequestId(id: number) {
    return this.getByListingId(id).pipe(map((demandRequest) => demandRequest.authorisers));
  }

  getDemandStatusByListingId(id: number) {
    return this.getByListingId(id).pipe(map((demandRequest) => demandRequest.status));
  }

  getIsPendingDemandRequest(id: number) {
    return this.getDemandStatusByListingId(id).pipe(map((status) => status === 'UNDER_REVIEW'));
  }

  getIsRejectedDemandRequest(id: number) {
    return this.getDemandStatusByListingId(id).pipe(map((status) => status === 'REJECTED'));
  }
}
