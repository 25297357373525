import { signalableFactory as actionableFactory } from './form.adapter';

export class ApproveDemandRequest extends actionableFactory.create<
  'Approve Demand Request',
  { id: number }
>('Approve Demand Request') {}

export class RejectDemandRequest extends actionableFactory.create<
  'Reject Demand Request',
  { id: number }
>('Reject Demand Request') {}
