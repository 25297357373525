import {
  adapter,
  initialState,
  paginationAdapter,
} from './external-approved-rate-escalation-level.adapter';
import {
  ExternalApprovedRateEscalationLevelMessages,
  ExternalApprovedRateEscalationLevelMessageTypes as MessageTypes,
} from './external-approved-rate-escalation-level.messages';
import { IExternalApprovedRateEscalationLevelState } from './interfaces';

export const featureKey = 'externalApprovedRateEscalationLevel';

export const paginationReducer = paginationAdapter.createReducer();

export function entityReducer(
  state = initialState,
  action: ExternalApprovedRateEscalationLevelMessages,
): IExternalApprovedRateEscalationLevelState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }
    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }
    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }
    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }
    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }
    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }
    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }
    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }
    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
