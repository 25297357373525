import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
  signal,
} from '@angular/core';
import { TippyDirective } from '@ngneat/helipopper';
import { FormControlState, NgrxFormsModule } from 'ngrx-forms';

import { ButtonComponent, TextAreaComponent } from '@locumsnest/components/src';

@Component({
  selector: 'ln-demand-control-pending-step',
  standalone: true,
  imports: [TextAreaComponent, ButtonComponent, NgrxFormsModule, TippyDirective],
  templateUrl: './demand-control-pending-step.component.html',
  styleUrl: './demand-control-pending-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.can-action]': 'canReject()',
  },
})
export class DemandControlPendingStepComponent {
  stepTitle = input.required<string>();
  name = input.required<string>();
  canAuthorise = input(false);
  canReject = input(false);
  authoriserNoteControl = input.required<FormControlState<string>>();

  showConfirmation = signal({ approve: false, reject: false });

  @Output() approve = new EventEmitter<void>();
  @Output() reject = new EventEmitter<void>();

  notesPlaceholder = computed(() =>
    this.showConfirmation().approve
      ? 'Write an optional note to the requester'
      : 'Write a note to the requester',
  );

  onConfirm() {
    if (this.showConfirmation().approve) this.approve.emit();
    else this.reject.emit();
  }

  onCancel() {
    this.showConfirmation.set({ approve: false, reject: false });
  }

  onShowConfirmation(action: 'approve' | 'reject') {
    if (action === 'approve')
      this.showConfirmation.update((showConfirmation) => ({ ...showConfirmation, approve: true }));
    else
      this.showConfirmation.update((showConfirmation) => ({ ...showConfirmation, reject: true }));
  }
}
