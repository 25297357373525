import { messageableFactory as actionableFactory } from './form.adapter';

export class InitializeDemandControlFormMessage extends actionableFactory.create<
  'Initialize Demand Control Message',
  Record<string, never>
>('Initialize Demand Control Message') {}

export class LoadJobListingAfterDemandActionMessage extends actionableFactory.create<
  'Load JobListing After Demand Action',
  { id: number }
>('Load JobListing After Demand Action') {}

export type DemandControlFormMessages = InitializeDemandControlFormMessage;
