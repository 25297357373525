import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { paginationAdapter } from './demand-request.adapter';
import { IDemandRequestEntity } from './interfaces';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();
export class UpsertDemandRequestPageMessage extends UpsertPageMessage {}
export class UpsertDemandRequestMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetDemandRequestPaginationMessage extends ResetPaginationMessage {}

export enum DemandRequestMessageTypes {
  SET_COLLECTION = '[DemandRequest][Message] Set Collection',
  ADD_ONE = '[DemandRequest][Message] Add One',
  UPDATE_ONE = '[DemandRequest][Message] Update One',
  UPSERT_ONE = '[DemandRequest][Message] Upsert One',
  DELETE_ONE = '[DemandRequest][Message] Delete One',
  ADD_MULTIPLE = '[DemandRequest][Message] Add All',
  DELETE_MULTIPLE = '[DemandRequest][Message] Delete Many',
  UPSERT_MULTIPLE = '[DemandRequest][Message] Upsert Many',
  UPDATE_MULTIPLE = '[DemandRequest][Message] Update Many',
}

export class SetCollectionMessage implements Action {
  readonly type = DemandRequestMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IDemandRequestEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = DemandRequestMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IDemandRequestEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = DemandRequestMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IDemandRequestEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = DemandRequestMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IDemandRequestEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = DemandRequestMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IDemandRequestEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = DemandRequestMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IDemandRequestEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = DemandRequestMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IDemandRequestEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = DemandRequestMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = DemandRequestMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type DemandRequestMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
