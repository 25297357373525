import { inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { EMPTY, first, map, Observable, switchMap } from 'rxjs';

import { PaginatedStateService, uniqueArr } from '@locumsnest/core/src';

import { APPROVED_RATE_INDEX } from './external-approved-rate-escalation-level.adapter';
import {
  paginationMessages,
  ResetExternalApprovedRateEscalationLevelPaginationMessage,
  UpsertExternalApprovedRateEscalationLevelPageMessage,
  UpsertMultipleMessage,
} from './external-approved-rate-escalation-level.messages';
import { ExternalApprovedRateEscalationLevelPersistenceService } from './external-approved-rate-escalation-level.persistence.service';
import {
  externalApprovedRateEscalationLevelPaginationSelectors,
  selectExternalApprovedRateEscalationLevelEntityState,
} from './external-approved-rate-escalation-level.selectors';
import { IExternalApprovedRateEscalationLevelEntity } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ExternalApprovedRateEscalationLevelService extends PaginatedStateService<
  IExternalApprovedRateEscalationLevelEntity,
  UpsertExternalApprovedRateEscalationLevelPageMessage,
  ResetExternalApprovedRateEscalationLevelPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(ExternalApprovedRateEscalationLevelPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return externalApprovedRateEscalationLevelPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalApprovedRateEscalationLevelEntityState;
  }

  fetch() {
    return this.initializePagination('default', {});
  }

  getByApprovedRateIds(ids: number[]) {
    return this.getMultipleByMultipleIndexes(APPROVED_RATE_INDEX, ids);
  }

  loadManyIfNotExists(ids: number[]): Observable<Action> {
    return this.getEntityDict().pipe(
      first(),
      switchMap((entities) => {
        const uniqueIds = uniqueArr(ids);
        const idsToLoad: number[] = [];
        uniqueIds.forEach((id) => {
          if (!entities[id]) idsToLoad.push(id);
        });

        if (idsToLoad.length)
          return this.persistenceService
            .retrieve({ approvedRates: uniqueArr(ids) })
            .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
        return EMPTY;
      }),
    );
  }
}
