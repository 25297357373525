import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { IDemandRequestEntity } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class DemandRequestPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IDemandRequestEntity
> {
  protected override readonly endpoint = 'demandRequest';

  approveDemandRequest(id: number, note: string) {
    return this.create<{ authorisationNote: string }, IDemandRequestEntity>(
      { authorisationNote: note },
      { pathParams: { id }, controllerResource: 'demandRequestApprove', skipSerializer: true },
    );
  }

  rejectDemandRequest(id: number, note: string) {
    return this.create<{ rejectionNote: string }, IDemandRequestEntity>(
      { rejectionNote: note },
      { pathParams: { id }, controllerResource: 'demandRequestReject', skipSerializer: true },
    );
  }
}
