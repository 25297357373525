import { createFormGroupState, createFormStateReducerWithUpdate, FormGroupState } from 'ngrx-forms';

import { DemandControlFormState } from '../interfaces';
import { DemandControlFormMessages, InitializeDemandControlFormMessage } from './form.messages';

export const FORM_ID = 'DEMAND_CONTROL';

const INITIAL_FORM_STATE = createFormGroupState<DemandControlFormState>(FORM_ID, {
  authoriserNote: '',
});
const formStateReducer = createFormStateReducerWithUpdate<DemandControlFormState>([]);

export function reducer(
  state: FormGroupState<DemandControlFormState> = INITIAL_FORM_STATE,
  action: DemandControlFormMessages,
) {
  switch (action.type) {
    case InitializeDemandControlFormMessage.TYPE:
      state = createFormGroupState<DemandControlFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      break;
  }

  state = formStateReducer(state, action);

  return state;
}
