import { animate, group, query, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
  signal,
} from '@angular/core';
import { FormControlState } from 'ngrx-forms';

import { IconButtonComponent } from '@locumsnest/components/src/lib/atoms/icon-button/icon-button.component';

import { DemandControlApprovedStepComponent } from '../demand-control-approved-step/demand-control-approved-step.component';
import { DemandControlPendingStepComponent } from '../demand-control-pending-step/demand-control-pending-step.component';

@Component({
  selector: 'ln-demand-control-widget',
  standalone: true,
  imports: [
    IconButtonComponent,
    DemandControlApprovedStepComponent,
    DemandControlPendingStepComponent,
  ],
  templateUrl: './demand-control-widget.component.html',
  styleUrl: './demand-control-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expanded', [
      transition(
        '* => *',
        [
          query(':self', [style({ height: '{{startHeight}}px' })]),
          query(':enter', [style({ opacity: 0, scale: 0.9 })], { optional: true }),
          query(
            ':leave',
            [
              style({ opacity: 1, scale: 1 }),
              animate('0.1s ease-in', style({ opacity: 0, scale: 0.9 })),
            ],
            { optional: true },
          ),
          group([
            query(':self', [animate('0.2s ease-in', style({ height: '*' }))]),
            query(':enter', animate('0.2s ease-in', style({ opacity: 1, scale: 1 })), {
              optional: true,
            }),
          ]),
        ],
        { params: { startHeight: 0 } },
      ),
    ]),
  ],
})
export class DemandControlWidgetComponent implements AfterViewInit {
  shiftCreatedDate = input.required<string>();
  requestedBy = input.required<string>();
  requestedDate = input<string>('');
  requestNote = input('');
  primaryAuthorisedBy = input.required<string>();
  primaryAuthorisedDate = input<string>('');
  primaryAuthoriserNote = input('');
  enhancedAuthorisedBy = input.required<string>();
  enhancedAuthorisedDate = input<string>('');
  enhancedAuthoriserNote = input('');
  vacancyReason = input('');
  canAuthorisePrimary = input(false);
  canAuthoriseEnhanced = input(false);
  rejectedBy = input('');
  rejectionReason = input('');
  rejectedDate = input('');
  canRejectPrimary = input(true);
  canRejectEnhanced = input(true);
  primaryAuthoriserNoteControl = input.required<FormControlState<string>>();

  @Output() approve = new EventEmitter<void>();
  @Output() reject = new EventEmitter<void>();

  ngAfterViewInit() {
    if (this.rejectedDate() || this.shiftReleasedDate()) return;
    setTimeout(() => this.expanded.set(true), 500);
  }

  shiftReleasedBy = computed(() =>
    this.enhancedAuthorisedDate()
      ? this.enhancedAuthorisedBy()
      : this.primaryAuthorisedDate() && !this.enhancedAuthorisedBy()
        ? this.primaryAuthorisedBy()
        : '',
  );

  shiftReleasedDate = computed(() =>
    this.enhancedAuthorisedDate()
      ? this.enhancedAuthorisedDate()
      : this.primaryAuthorisedDate() && !this.enhancedAuthorisedBy()
        ? this.primaryAuthorisedDate()
        : '',
  );

  demandRequestActive = computed(() => !this.primaryAuthorisedDate() && !this.rejectedDate());

  primaryAuthoriseActive = computed(
    () =>
      !!this.primaryAuthorisedDate() &&
      !this.enhancedAuthorisedDate() &&
      !!this.enhancedAuthorisedBy() &&
      !this.rejectedDate(),
  );

  expanded = signal(false);

  toggleExpanded() {
    this.expanded.update((val) => !val);
  }

  onApprove() {
    return this.approve.emit();
  }

  onReject() {
    return this.reject.emit();
  }
}
