import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, index } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IMultipleIndexField } from '@locumsnest/core/src/lib/ngrx/interfaces';

import { IExternalApprovedRateEscalationLevelEntity } from './interfaces';

export const APPROVED_RATE_INDEX: IMultipleIndexField = {
  fieldName: 'approvedRate',
  unique: false,
  reAssignMent: false,
};
const indexEntity = index([APPROVED_RATE_INDEX]);

const namespace = 'ExternalApprovedRateEscalationLevel';
const adapter = createEntityAdapter<IExternalApprovedRateEscalationLevelEntity>({}, indexEntity);
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
);
export { adapter, initialState, paginationAdapter };
