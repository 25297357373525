/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import {
  ExternalApprovedRateEscalationLevelService,
  IExternalApprovedRateEscalationLevelEntity,
} from '@ln/external-approved-rate-escalation-level';
import { Action, select } from '@ngrx/store';
import { get, isNil, isNumber, isString, uniq, uniqBy } from 'lodash-es';
import { SetValueAction } from 'ngrx-forms';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  timer,
} from 'rxjs';

import { IQueryParams, PaginatedStateService, uniqueArr } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';
import { ISetValueForResourceMessageConstructor } from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';
import { CurrencySymbolPipe } from '@locumsnest/core/src/lib/pipes';

import { DEFAULT_CURRENCY, PROCESS_STATUSES } from '../../core/constants';
import { LoadProfileSettings } from '../../core/services/interfaces';
import { ExternalStaffingProviderOfficerService } from '../../external-staffing-provider-officer/+state/external-staffing-provider-officer.service';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { ProfileFlagService } from '../../profile-flag/+state/profile-flag.service';
import { ProfileService } from '../../profile/+state/profile.service';
import { StaffBankMembershipRequestService } from '../../staff-bank-membership-request/+state/staff-bank-membership-request.service';
import { StaffBankMembershipService } from '../../staff-bank-membership/+state/staff-bank-membership.service';
import { ExternalStaffingCandidateBidStatusService } from './../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import {
  IAdjacentBidEntity,
  IBidFragmentWithRates,
  IExternalStaffingCandidateBidEntity,
  IJobListingEntityWithRates,
} from './../../interfaces/api/external-staffing-candidate-bid-entity';
import { getBidMultiResourceId } from './../util/multi-resource';
import {
  adjacentBidsAdapter,
  paginationAdapter,
  STAFFING_CASCADE_INDEX,
} from './external-staffing-candidate-bid.adapter';
import {
  ExternalStaffingCandidateBidMessageTypes,
  externalStaffingCandidateBidPaginationMessages,
  LoadExternalApprovedRateEscalationLevelsMessage,
  ResetExternalStaffingCandidateBidPaginationMessage,
  UpsertExternalStaffingCandidateBidPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './external-staffing-candidate-bid.messages';
import { ExternalStaffingCandidateBidPersistenceService } from './external-staffing-candidate-bid.persistence.service';
import {
  externalStaffingCandidateBidPaginationSelectors,
  selectAllExternalStaffingCandidateBids,
  selectExternalStaffingCandidateBid,
  selectExternalStaffingCandidateBidEntityState,
  selectExternalStaffingCandidateBidState,
} from './external-staffing-candidate-bid.selectors';
import { calculateApprovedFlatRate, INITIAL_FORM_ENTITY_STATE } from './form/form.reducer';
import * as formSelectors from './form/form.selectors';
import { IBidFragmentFormState } from './interfaces/external-staffing-candidate-bid-form';
import { ISignature } from './interfaces/signature';
import { INITIAL_MULTI_BID_FORM_ENTITY_STATE } from './multi-bid-form/multi-bid-form.reducer';
import * as multiBidFormSelectors from './multi-bid-form/multi-bid-form.selectors';
import { selectExternalStaffingCandidateBidUiFormState } from './ui-form/ui-form.selectors';
import {
  selectActiveStepOne,
  selectActiveStepTwo,
  selectCompleteStepOne,
  selectCompleteStepTwo,
  selectExternalStaffingCandidateBidUiState,
  selectSelectedBid,
  selectShowFileUploader,
} from './ui/ui.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateBidService extends PaginatedStateService<
  IExternalStaffingCandidateBidEntity,
  UpsertExternalStaffingCandidateBidPageMessage,
  ResetExternalStaffingCandidateBidPaginationMessage,
  UpsertMultipleMessage
> {
  static readonly DEFAULT_LOAD_PROFILE_SETTINGS: LoadProfileSettings = {
    loadProfileFlag: true,
    loadProfile: false,
    loadStaffBanks: false,
    loadStaffBankRequests: false,
    loadAssignmentNumbers: false,
    loadCandidateBids: false,
  };

  protected readonly SetValueForAdjacentBidsMessageClass: ISetValueForResourceMessageConstructor<
    string,
    IAdjacentBidEntity[]
  > = adjacentBidsAdapter.getMessages().SetValueForResource;
  protected readonly adjacentBidSelectors = adjacentBidsAdapter.getSelectors(
    selectExternalStaffingCandidateBidState,
  );

  private currencySymbol = new CurrencySymbolPipe();
  constructor(
    protected persistenceService: ExternalStaffingCandidateBidPersistenceService,
    private profileService: ProfileService,
    private staffBankMembershipService: StaffBankMembershipService,
    private staffBankMembershipRequestService: StaffBankMembershipRequestService,
    private profileFlagService: ProfileFlagService,
    private externalStaffingProviderOfficerService: ExternalStaffingProviderOfficerService,
    private externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService,
    private externalApprovedRateEscalationLevelService: ExternalApprovedRateEscalationLevelService,
  ) {
    super();
  }
  get paginationMessages() {
    return externalStaffingCandidateBidPaginationMessages;
  }

  get paginationSelectors() {
    return externalStaffingCandidateBidPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingCandidateBidEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingCandidateBids));
  }

  getOne(id) {
    return this.store.pipe(
      select(selectExternalStaffingCandidateBid(id)),
      filter((bid) => !!bid),
    );
  }

  filterByCascadeId(bids: IExternalStaffingCandidateBidEntity[], cascadeId: number) {
    return bids.filter(({ staffingCascade }) => staffingCascade === cascadeId);
  }
  getOneImmediately(id: number) {
    return this.store.pipe(select(selectExternalStaffingCandidateBid(id)), first());
  }
  getAllAfterLoadingByCascadeId(cascadeId: number) {
    return this.getAllAfterLoading().pipe(map((bids) => this.filterByCascadeId(bids, cascadeId)));
  }

  getAllOrEmptyByCascadeId(cascadeId: number) {
    return this.getMultipleOrEmptyByIndex(STAFFING_CASCADE_INDEX, cascadeId);
  }

  getAllByCascadeId(cascadeId: number) {
    return this.getMultipleByIndex(STAFFING_CASCADE_INDEX, cascadeId);
  }

  getPendingByCascadeId(cascadeId: number) {
    return combineLatest([
      this.externalStaffingCandidateBidStatusService.getSubmittedStatusCode(),
      this.getAllOrEmptyByCascadeId(cascadeId),
    ]).pipe(map(([status, bids]) => bids.filter((bid) => bid.status === status.val)));
  }

  getAllByCascadeIdWithDetails(cascadeId: number) {
    return this.getDetails(this.getAllByCascadeId(cascadeId));
  }
  getAdjacentBidDict() {
    return this.store.pipe(select(this.adjacentBidSelectors.selectSubResourceState));
  }
  getFormState() {
    return this.store.pipe(select(formSelectors.selectExternalStaffingCandidateBidFormState));
  }

  getProviderFeeIsInvalid() {
    return this.store.pipe(select(formSelectors.selectProviderFeeIsInvalid));
  }

  getSelectedProfileId() {
    return this.store.pipe(select(formSelectors.selectProfileId));
  }
  getUploadFileFormState() {
    return this.store.pipe(select(formSelectors.selectUploadFileFormState));
  }

  getFormProfileId() {
    return this.store.pipe(select(formSelectors.selectProfileId));
  }
  getFormDirectEngagementCandidate() {
    return this.store.pipe(select(formSelectors.selectDirectEngagementCandidate));
  }

  getFormLegacyApprovedFee() {
    return this.store.pipe(select(formSelectors.selectLegacyApprovedFee));
  }
  getFormLegacyApprovedFeeExists() {
    return this.store.pipe(select(formSelectors.selectLegacyApprovedFeeExists));
  }
  getFormAgencyFeeApproved() {
    return this.store.pipe(select(formSelectors.selectFormFeeApproved));
  }
  getFormGrade() {
    return this.store.pipe(select(formSelectors.selectGradeId));
  }
  getHasBidId() {
    return this.store.pipe(select(formSelectors.selectHasBidId));
  }
  getFormStaffingProviderControl() {
    return this.store.pipe(select(formSelectors.selectStaffingProviderControl));
  }
  getFormCandidateEmailControl() {
    return this.store.pipe(select(formSelectors.selectCandidateEmailControl));
  }
  getFormEscalationNoteControl() {
    return this.store.pipe(select(formSelectors.selectEscalationNoteControl));
  }
  getFlatRateControl() {
    return this.store.pipe(select(formSelectors.selectFlatRateControl));
  }
  getGradeControl() {
    return this.store.pipe(select(formSelectors.selectGradeControl));
  }
  getProviderFeeControl() {
    return this.store.pipe(select(formSelectors.selectProviderFeeControl));
  }
  getDirectEngagementCandidateControl() {
    return this.store.pipe(select(formSelectors.selectDirectEngagementCandidateControl));
  }
  getApprovedProposedRateControl() {
    return this.store.pipe(select(formSelectors.selectApprovedProposedRateControl));
  }
  getPerFragmentFeesApplicable() {
    return this.store.pipe(select(formSelectors.selectPerFragmentFeesApplicable));
  }
  getCandidateHasBankDetails() {
    return this.store.pipe(select(formSelectors.selectCandidateHasBankDetailsValue));
  }
  getCandidateHasPreferablePaymentMethod() {
    return this.store.pipe(select(formSelectors.selectCandidateHasPreferablePaymentMethodValue));
  }
  getBidFragmentsControl() {
    return this.store.pipe(select(formSelectors.selectBidFragmentsControl));
  }

  getDocumentDeclarationAcceptedControl() {
    return this.store.pipe(select(formSelectors.selectDocumentDeclarationAcceptedControl));
  }
  getSupportingDocumentDeclaration() {
    return this.store.pipe(select(formSelectors.selectSupportingDocumentDeclaration));
  }
  getFormStaffingProviderValue() {
    return this.store.pipe(select(formSelectors.selectStaffingProviderValue));
  }
  getIdsToMarkAsDirty() {
    return this.store.pipe(select(formSelectors.selectIdsToMarkAsDirty));
  }
  getFormId() {
    return this.store.pipe(
      select(formSelectors.selectCurrentExternalCandidate),
      distinctUntilChanged(),
    );
  }
  getFormHospitalId(): Observable<number> {
    return this.store.pipe(select(formSelectors.selectHospitalId), distinctUntilChanged());
  }

  getAllForProfile(profile: string) {
    return this.getAll().pipe(map((entities) => entities.filter((e) => e.profile === profile)));
  }

  getExistingBidSignature(id): Observable<ISignature> {
    return this.getOne(id).pipe(
      map(({ submittedBy, lastUpdate }) => ({ ...submittedBy, signedAt: lastUpdate })),
    );
  }
  getNewBidSignature(signatureUpdateInterval = 60 * 1000): Observable<ISignature> {
    return combineLatest([
      this.externalStaffingProviderOfficerService.getAssignedUserDetails(),
      timer(0, signatureUpdateInterval),
    ]).pipe(
      map(([userDetails]) => ({
        ...userDetails,
        signedAt: Time.getDate(),
      })),
    );
  }

  getBidFragmentsOrApprovedRates(
    approvedRates: IBidFragmentFormState[],
    bidFragments: IBidFragmentFormState[],
    approvedProposedRate: boolean,
    providerFeeApprovedRate: boolean,
  ) {
    if (approvedProposedRate && providerFeeApprovedRate) return approvedRates;

    const fragments = bidFragments.map((fragment) => {
      let newValue = { ...fragment };
      const approvedRate = approvedRates.find((aR) => aR.approvedRate === fragment.approvedRate);

      if (approvedRate) {
        if (approvedProposedRate) {
          newValue = { ...newValue, payRate: approvedRate.payRate };
        }
        if (providerFeeApprovedRate) {
          newValue = { ...newValue, agencyFee: approvedRate.agencyFee };
        }
      }
      return newValue;
    });

    return fragments;
  }

  getBidFragmentsAndApprovedRates() {
    return this.store.pipe(select(formSelectors.selectBidFragmentsAndApprovedRates));
  }

  defaultToSelectedBidFragments(bidFragmentsForm: IBidFragmentFormState[] | null = null) {
    return bidFragmentsForm
      ? of(bidFragmentsForm)
      : this.getBidFragmentsAndApprovedRates().pipe(
          filter((rates) => !!rates.approvedRates),
          map(({ approvedProposedRate, approvedRate, approvedRates, bidFragments }) =>
            this.getBidFragmentsOrApprovedRates(
              approvedRates,
              bidFragments,
              approvedProposedRate,
              approvedRate,
            ),
          ),
        );
  }

  showRateEscalationError(bidFragments: IBidFragmentFormState[] | null = null) {
    return this.defaultToSelectedBidFragments(bidFragments).pipe(
      filterNonEmpty,
      switchMap((fragments) =>
        this.externalApprovedRateEscalationLevelService
          .getByApprovedRateIds(uniqueArr(fragments.map((c) => c.approvedRate)))
          .pipe(
            map((externalRates) => {
              const externalRateMap = new Map<
                number,
                IExternalApprovedRateEscalationLevelEntity[]
              >();
              externalRates.forEach((rate) => {
                if (!externalRateMap.has(rate.approvedRate)) {
                  externalRateMap.set(rate.approvedRate, []);
                }
                externalRateMap.get(rate.approvedRate)?.push(rate);
              });
              const combinedRates = fragments.map((rate) => {
                const escalationLevels = externalRateMap.get(rate.approvedRate) || [];

                const payRateNum = +rate.payRate;
                const agencyFee = +rate.agencyFee;
                const totalPay = (payRateNum + agencyFee).toFixed(2);

                // Find the maximum boundaryMax
                const maxBoundaryMax = Math.max(
                  ...escalationLevels.map((el) => +el.chargeRateMax),
                  0,
                );

                // Check if payRate falls within any boundary range
                const isPayRateWithinBoundaries = escalationLevels.some(
                  (el) => +totalPay >= +el.chargeRateMin && +totalPay <= +el.chargeRateMax,
                );

                // Check if payRate exceeds the maximum boundaryMax
                const isPayRateAboveBoundaryMax = +totalPay > maxBoundaryMax;

                return {
                  ...rate,
                  escalationLevels,
                  isPayRateAboveBoundaryMax,
                  isPayRateWithinBoundaries,
                };
              });
              return combinedRates.some((cR) => cR.isPayRateWithinBoundaries);
            }),
          ),
      ),
      distinctUntilChanged(),
    );
  }

  getCurrentSignature(newSignatureUpdateInterval = 60 * 1000): Observable<ISignature> {
    return this.getFormId().pipe(
      mergeMap((formId) => {
        if (formId) {
          return this.getExistingBidSignature(formId);
        }
        return this.getNewBidSignature(newSignatureUpdateInterval);
      }),
    );
  }

  getExternalStaffingCandidateBidUiFormState() {
    return this.store.pipe(select(selectExternalStaffingCandidateBidUiFormState));
  }

  getExternalStaffingCandidateBidUiState() {
    return this.store.pipe(select(selectExternalStaffingCandidateBidUiState));
  }

  getActiveStepOne() {
    return this.store.pipe(select(selectActiveStepOne));
  }

  getActiveStepTwo() {
    return this.store.pipe(select(selectActiveStepTwo));
  }

  getCompleteStepOne() {
    return this.store.pipe(select(selectCompleteStepOne));
  }

  getCompleteStepTwo() {
    return this.store.pipe(select(selectCompleteStepTwo));
  }

  getShowFileUploader() {
    return this.store.pipe(select(selectShowFileUploader));
  }

  getSelectedBid() {
    return this.store.pipe(select(selectSelectedBid));
  }
  getSelectedBidEntity() {
    return this.getSelectedBid().pipe(switchMap((id) => this.getOne(id)));
  }

  getProcessingStatusDisplay(id: number) {
    return PROCESS_STATUSES.filter((status) => status.id === id)[0].display;
  }

  loadOneIfNotExists(id: number) {
    return this.getOneImmediately(id).pipe(
      mergeMap((bid) => {
        if (bid) {
          return of<Action>();
        }
        return this.fetchOne(id, {
          ...ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
          loadProfileFlag: false,
        });
      }),
    );
  }

  loadCascadeBidList(cascadeID: number) {
    const namespace = `Cascade ${cascadeID} bid list`;
    return this.isPendingOrLoaded(namespace).pipe(
      first(),
      mergeMap((loaded) => {
        if (!loaded) {
          return this.loadByStaffingCascadeIds([cascadeID], {
            ...ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
            loadProfileFlag: false,
            loadProfile: true,
          });
        }
      }),
    );
  }

  loadByStaffingCascadeIds(
    ids: number[],
    loadProfile: LoadProfileSettings = null,
    loadEscalationRequests = false,
  ) {
    return this.loadAllWithDependencies(
      { staffingCascade: ids },
      loadProfile,
      loadEscalationRequests,
    );
  }

  loadAllWithDependencies(params, loadProfileSettings, loadEscalationRequests: boolean) {
    return this.loadAll(params).pipe(
      mergeMap((action) =>
        merge(
          of(action),
          this.loadDependencies(action, loadProfileSettings, loadEscalationRequests),
        ),
      ),
    );
  }

  loadByIds(ids: number[], loadProfileSettings: LoadProfileSettings) {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ id: ids }, loadProfileSettings),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  fetchLast(query: IQueryParams, formId: string, email?: string) {
    return this.persistenceService
      .retrieve({
        ...query,
        ordering: '-created_at',
        pageSize: 1,
      })
      .pipe(
        map((results) => results.results[0]),
        switchMap((bid) =>
          merge(
            of(new SetValueAction(formId + '.candidateEmail', email ?? get(bid, 'candidateEmail'))),
            of(new SetValueAction(formId + '.nameOnAccount', get(bid, 'nameOnAccount'))),
            of(new SetValueAction(formId + '.bankAccountNumber', get(bid, 'bankAccountNumber'))),
            of(new SetValueAction(formId + '.sortCode', get(bid, 'sortCode'))),
            of(
              new SetValueAction(
                formId + '.directEngagementCandidate',
                get(bid, 'directEngagementCandidate'),
              ),
            ),
          ),
        ),
      );
  }

  fetchOneForProfileIfNotExists(profileId: string) {
    return this.getAll().pipe(
      first(),
      map((entities) => entities.filter((x) => x.profile === profileId)),
      switchMap((entities) => {
        if (entities.length) return EMPTY;
        return this.persistenceService
          .retrieve({
            profile: profileId,
            pageSize: 1,
          })
          .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
      }),
    );
  }

  fetchBidsForProfileExcludingProvider(id: number, profileId: string) {
    const shouldLoadMore$ = this.getAll().pipe(
      first(),
      map((res) => res.filter((x) => x.profile === profileId)),
      map((res) => uniqBy(res, 'staffingProvider')),
      map((res) => res.length < 2),
    );

    return shouldLoadMore$.pipe(
      switchMap((shouldLoadMore) => {
        if (shouldLoadMore) {
          return this.persistenceService
            .retrieve({
              staffing_provider__not: id,
              profile: profileId,
              pageSize: 1,
            })
            .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
        }
        return EMPTY;
      }),
    );
  }

  fetchLastByStartTime(query: IQueryParams) {
    return this.persistenceService
      .retrieve({
        ...query,
        ordering: '-start_time',
        pageSize: 1,
      })
      .pipe(map((results) => results.results[0]));
  }

  private fetchOne(query: string | number, loadProfileSettings: LoadProfileSettings) {
    return this.persistenceService.retrieve(query).pipe(
      mergeMap((entity) => {
        const actions: Observable<Action>[] = [of(new UpsertOneMessage({ entity }))];
        if (!isNil(loadProfileSettings) && !isNil(entity.profile)) {
          if (loadProfileSettings.loadProfile) {
            actions.push(this.profileService.loadOne(entity.profile));

            if (loadProfileSettings.loadProfileFlag) {
              actions.push(this.profileFlagService.loadByProfileIds([entity.profile]));
            }
          }
          if (loadProfileSettings.loadStaffBanks) {
            actions.push(this.staffBankMembershipService.loadByProfileIds([entity.profile]));
          }
          if (loadProfileSettings.loadStaffBankRequests) {
            actions.push(this.staffBankMembershipRequestService.loadByProfileIds([entity.profile]));
          }
        }
        return merge(...actions);
      }),
    );
  }
  private getDetails(
    bids$: Observable<IExternalStaffingCandidateBidEntity<number, number, string, number>[]>,
  ): Observable<
    IExternalStaffingCandidateBidEntity<
      number,
      number,
      IProfileEntity,
      IExternalStaffingCandidateBidStatusEntity
    >[]
  > {
    return bids$.pipe(
      switchMap((bids) => {
        if (!bids.length) {
          return of([]);
        }
        return combineLatest(
          bids.map((bid) =>
            combineLatest([
              this.externalStaffingCandidateBidStatusService.getOne(bid.bookingStatus),
              this.profileService.getOne(bid.profile),
            ]).pipe(map(([bookingStatus, profile]) => ({ ...bid, bookingStatus, profile }))),
          ),
        );
      }),
    );
  }
  private fetchMultiple(
    query?: IQueryParams,
    // eslint-disable-next-line max-len
    loadProfileSettings: LoadProfileSettings = ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
    namespace = 'default',
  ) {
    return this.loadAllPages(namespace, {}, query).pipe(
      mergeMap((action) =>
        merge(of(action), this.loadDependencies(action, loadProfileSettings, false)),
      ),
    );
  }

  loadDependencies(
    action,
    loadProfileSettings: LoadProfileSettings,
    loadEscalationRequests: boolean,
  ) {
    const actions: Observable<Action>[] = [];
    if (
      action.type === ExternalStaffingCandidateBidMessageTypes.UPSERT_MULTIPLE ||
      action.type === ExternalStaffingCandidateBidMessageTypes.SET_COLLECTION
    ) {
      const profileIds: string[] = uniq(
        (action as UpsertMultipleMessage).payload.entities.map((result) => result.profile),
      );

      if (!isNil(loadProfileSettings) && profileIds.length > 0) {
        if (loadProfileSettings.loadProfile) {
          actions.push(this.profileService.loadByIds(profileIds));

          if (loadProfileSettings.loadProfileFlag) {
            actions.push(this.profileFlagService.loadByProfileIds(profileIds));
          }
        }

        if (loadProfileSettings.loadStaffBanks) {
          actions.push(this.staffBankMembershipService.loadByProfileIds(profileIds));
        }

        if (loadProfileSettings.loadStaffBankRequests) {
          actions.push(this.staffBankMembershipRequestService.loadByProfileIds(profileIds));
        }

        if (loadProfileSettings.loadCandidateBids) {
          const uniqEntities = uniqBy((action as UpsertMultipleMessage).payload.entities, [
            'staffingProvider',
            'profile',
          ]);

          uniqEntities.forEach((e) => {
            actions.push(this.fetchBidsForProfileExcludingProvider(e.staffingProvider, e.profile));
          });
        }

        if (loadEscalationRequests) {
          const bidIds = (action as UpsertMultipleMessage).payload.entities.map(
            (result) => result.id,
          );
          actions.push(of(new LoadExternalApprovedRateEscalationLevelsMessage({ bidIds })));
        }
      }
    }

    return actions.length ? merge(...actions) : EMPTY;
  }

  fetch(
    query?: Query,
    // eslint-disable-next-line max-len
    loadProfileSettings: LoadProfileSettings = ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
    namespace = 'default',
  ) {
    if (isString(query) || isNumber(query)) {
      return this.fetchOne(query, loadProfileSettings);
    }
    return this.fetchMultiple(query, loadProfileSettings, namespace);
  }

  getCandidateAmount(externalStaffingCandidateBid: IExternalStaffingCandidateBidEntity) {
    let candidateCost = 0;
    const bidFragments = externalStaffingCandidateBid.bidFragments;

    for (let i = 0; i < bidFragments.length; i++) {
      const totalHours = Time.getMoment(bidFragments[i].toTime).diff(
        Time.getMoment(bidFragments[i].fromTime),
        'hours',
        true,
      );
      const totalPay = totalHours * parseFloat(bidFragments[i].payRate);
      candidateCost += totalPay;
    }

    if (!isNil(externalStaffingCandidateBid.flatRate)) {
      candidateCost += parseFloat(externalStaffingCandidateBid.flatRate as string);
    }

    return candidateCost;
  }

  getEntityFormState(id) {
    return this.getOne(id).pipe(
      map((entity) => formSelectors.getEntityFormState(entity, INITIAL_FORM_ENTITY_STATE)),
      distinctUntilChanged(),
    );
  }

  getEntityMultiBidFormState(id) {
    return this.getOne(id).pipe(
      map((entity) =>
        multiBidFormSelectors.getConfirmRatesFormState(entity, INITIAL_MULTI_BID_FORM_ENTITY_STATE),
      ),
      distinctUntilChanged(),
    );
  }

  getNewEntityFormState(entity: IExternalStaffingCandidateBidEntity) {
    return formSelectors.getEntityFormState(entity, INITIAL_FORM_ENTITY_STATE);
  }

  getNewConfirmRatesFormState(entity: IExternalStaffingCandidateBidEntity) {
    return multiBidFormSelectors.getConfirmRatesFormState(
      entity,
      INITIAL_MULTI_BID_FORM_ENTITY_STATE,
    );
  }

  getNewFragmentsFormState(fragments: IBidFragmentWithRates[]) {
    return formSelectors.getFragmentsFormState(fragments);
  }
  getBidFlatRate(listing: IJobListingEntityWithRates, grade: number) {
    const listingGrade = listing.grades.find((x) => x.grade === grade);
    if (isNil(listingGrade)) {
      return null;
    }
    return calculateApprovedFlatRate(listingGrade.jobFragments);
  }
  getBidFragments(listing: IJobListingEntityWithRates, grade: number) {
    const listingGrade = listing.grades.find((x) => x.grade === grade);
    if (isNil(listingGrade)) {
      return [];
    }
    return listingGrade.jobFragments.map((jf) => ({
      payRate: jf.payRate.rate,
      payRateCurrency: jf.payRate.rateCurrency,
      nonResidentCalloutRate: jf.payRate.nonResidentCalloutRate,
      nonResidentCalloutRateCurrency: jf.payRate.nonResidentCalloutRateCurrency,
      fromTime: jf.timeFragment.fromTime,
      toTime: jf.timeFragment.toTime,
      payRateType: jf.payRate.payRateType,
      agencyFee: jf.agencyFee,
      nonResidentCalloutAgencyFee: jf.nonResidentCalloutAgencyFee,
      flatRate: jf.flatRate + '',
      approvedRate: jf.approvedRate,
    }));
  }

  getAgencyAmount(
    externalStaffingCandidateBid: IExternalStaffingCandidateBidEntity<number, number, any, any>,
  ): string {
    const candidateCost = this.getCandidateAmount(externalStaffingCandidateBid);
    const fee = +get(externalStaffingCandidateBid.providerFee, 'fee', 0);
    if (fee > 0) {
      return (
        this.currencySymbol.transform(
          get(externalStaffingCandidateBid.providerFee, 'feeCurrency', DEFAULT_CURRENCY),
        ) + fee
      );
    }

    return (
      this.currencySymbol.transform(
        get(externalStaffingCandidateBid.providerFee, 'feeCurrency', DEFAULT_CURRENCY),
      ) +
      (
        (candidateCost * +get(externalStaffingCandidateBid.providerFee, 'feePercentage', 0)) /
        100
      ).toFixed(2)
    );
  }

  importBid(e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .importBid(e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  create(e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .create(e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  update(id: number, e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .update(id, e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  getProfilesAfterCurrentPageLoad(namespace) {
    return this.getConsecutivePageEntities(namespace).pipe(
      mergeMap((bids: IExternalStaffingCandidateBidEntity[]) => {
        if (!bids.length) {
          return of([]);
        }

        const bidProfileIds = bids.map((bid) => bid.profile);
        return this.profileService.getMultiple(bidProfileIds);
      }),
      distinctUntilChanged(),
    );
  }

  loadAdjacent(multiResourceId: string, profile: string, startTime: Date) {
    return this.persistenceService.fetchAdjacent(profile, startTime).pipe(
      map(
        (subResource) =>
          new this.SetValueForAdjacentBidsMessageClass({
            resourceId: multiResourceId,
            subResource: subResource.map((adjacentBid) => ({
              ...adjacentBid,
              multiResourceId: getBidMultiResourceId(adjacentBid.bidId),
            })),
          }),
      ),
    );
  }

  getBidStartTime(id: number) {
    return this.getOne(id).pipe(
      map(({ startTime }) => startTime),
      distinctUntilChanged(),
    );
  }

  getBidProfile(id: number) {
    return this.getOne(id).pipe(
      map(({ profile }) => profile),
      distinctUntilChanged(),
    );
  }
  getBidDirectEngagementCandidate(id: number) {
    return this.getOne(id).pipe(
      map(({ directEngagementCandidate }) => directEngagementCandidate),
      distinctUntilChanged(),
    );
  }

  initializePaginationForProfile(namespace: string, profile: string) {
    return this.initializePagination(
      `${namespace}-${profile}`,
      {},
      { profile, ordering: '-start_time' },
    );
  }

  loadNextForProfile(namespace: string, profile: string) {
    return this.loadNext(`${namespace}-${profile}`, {}, { profile, ordering: '-start_time' });
  }
}
