import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { flatMap } from 'lodash-es';
import { box, unbox } from 'ngrx-forms';

import { Time } from '@locumsnest/core/src/lib/helpers';

import { JobListingFormGroupState } from '../job-listing.reducer';
import * as fromForm from './form.selectors';
import { IJobListingFormUserDefinedProperties } from './form.user-defined-properties';

export function createJobListingFormSelectors(
  selectJobListingFormWizardState: MemoizedSelector<
    Record<string, unknown>,
    JobListingFormGroupState,
    DefaultProjectorFn<JobListingFormGroupState>
  >,
) {
  const selectShiftCreationFormControl = createSelector(
    selectJobListingFormWizardState,
    (form) => form.controls.shiftCreation,
  );

  const selectShiftCreationControlIsInvalid = createSelector(
    selectShiftCreationFormControl,
    (control) => control.isInvalid,
  );

  const selectGradesSectionControl = createSelector(
    selectJobListingFormWizardState,
    (form) => form.controls.gradesSection,
  );

  const selectRateViolationReasonControl = createSelector(
    selectGradesSectionControl,
    (form) => form.controls.rateViolationReason,
  );

  const selectRateViolationReasonValue = createSelector(
    selectGradesSectionControl,
    (form) => form.controls.rateViolationReason.value,
  );

  const selectGradesSectionControlIsInvalid = createSelector(
    selectGradesSectionControl,
    (control) => control.isInvalid,
  );
  const selectJobListingFormWizardStateErrors = createSelector(
    selectJobListingFormWizardState,
    (control) => control.errors,
  );
  const selectInsufficientRateCardConfigurationError = createSelector(
    selectJobListingFormWizardStateErrors,
    (errors) => errors.$insufficientRateCardConfiguration,
  );
  const selectJobListingFormWardValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.ward,
  );

  const selectJobListingFormRosterValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.roster,
  );

  const selectJobListingFormPrimaryProfessionSpecialtyValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.professionSpecialty,
  );

  const selectJobListingFormCostCentreNumberValue = createSelector(
    selectJobListingFormWizardState,
    fromForm.getCostCentreNumberValue,
  );

  const selectJobListingFormProfessionValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.profession,
  );
  const selectJobListingFormNonResidentOnCallValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.nonResidentOnCall,
  );
  const selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.crossCoveringProfessionSpecialties,
  );
  const selectIsCrossCoveringForm = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (value) => !!value.length,
  );
  const selectJobListingFormCrossCoveringProfessionSpecialtiesValue = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (crossCoveringProfessionSpecialties) =>
      flatMap(crossCoveringProfessionSpecialties, (obj) => unbox(obj.professionSpecialties)),
  );
  const selectJobListingFormProfessionSpecialtyValues = createSelector(
    selectJobListingFormPrimaryProfessionSpecialtyValue,
    selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
    (primary, crossCovering) => [primary, ...crossCovering],
  );
  const selectJobListingFormCrossCoveringProfessions = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (value) => value.map((x) => x.profession),
  );
  const selectJobListingFormNotesValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.jobListingNotes,
  );

  const selectJobListingFormDemandNotesValue = createSelector(
    selectGradesSectionControl,
    (form) => form.value.demandNote,
  );

  const selectCopyNoteAcrossRepetitionDateFormValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.copyNoteAcrossRepetitionDate,
  );

  const selectJobListingFormSiteValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.site,
  );

  const selectJobListingFormGradeValues = createSelector(
    selectJobListingFormWizardState,
    fromForm.getJobListingGradeValues,
  );

  const selectExtendedJobListingFormWizardState = createSelector(
    selectJobListingFormWizardState,
    fromForm.getExtendedFormState,
  );
  const selectJobListingFormVacancyReason = createSelector(
    selectJobListingFormWizardState,
    fromForm.getFormVacancyReason,
  );
  const selectJobListingFormId = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.id,
  );
  const selectJobListingFromForm = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.getEntity(),
  );
  const selectLocalJobListingFromForm = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.getEntity((timestamp) => Time.getDate(timestamp)),
  );

  const selectIsSectionDetailsInvalid = createSelector(
    selectJobListingFormWizardState,
    fromForm.isSectionDetailsInvalid,
  );

  const selectIsSectionDescriptionInvalid = createSelector(
    selectJobListingFormWizardState,
    fromForm.isSectionDescriptionInvalid,
  );

  const selectIsSectionGradesInvalid = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.isSectionGradesInvalid,
  );

  const selectIsExternalJobListingMode = createSelector(
    selectJobListingFormWizardState,
    fromForm.getIsExternalJobListingMode,
  );

  const selectIsFormInvalid = createSelector(
    selectIsSectionDetailsInvalid,
    selectIsSectionDescriptionInvalid,
    selectIsSectionGradesInvalid,
    (isSectionDetailsInvalid, isSectionDescriptionInvalid, isSectionGradesInvalid) =>
      isSectionDetailsInvalid || isSectionDescriptionInvalid || isSectionGradesInvalid,
  );

  const selectJobListingGradeControl = (id) =>
    createSelector(selectExtendedJobListingFormWizardState, fromForm.getJobListingGrade(id));

  const selectEnabledJobListingGrades = createSelector(
    selectGradesSectionControl,
    (gradesSection) =>
      gradesSection.controls.grades.controls.filter((grade) => grade.controls.grade.isEnabled),
  );

  const selectEnabledJobListingGradeIds = createSelector(selectEnabledJobListingGrades, (grades) =>
    grades.map((g) => g.value.grade),
  );

  const selectJobListingGradesAreValid = (grades: number[]) =>
    createSelector(selectEnabledJobListingGrades, (selectedListingGrades) => {
      const validatedGrades = selectedListingGrades.filter(({ value }) =>
        grades.includes(value.grade),
      );
      return !!validatedGrades.length && validatedGrades.every(({ isValid }) => isValid);
    });

  const selectProfessionGradesAreValid = (profession: number) =>
    createSelector(selectEnabledJobListingGrades, (selectedListingGrades) => {
      const validatedGrades = selectedListingGrades.filter(
        ({ userDefinedProperties }) => userDefinedProperties.profession === profession,
      );
      return !!validatedGrades.length && validatedGrades.every(({ isValid }) => isValid);
    });

  const selectShiftSchedulerControl = createSelector(
    selectExtendedJobListingFormWizardState,
    (form) => form.controls.shiftScheduler,
  );
  const selectStartTimeFormControl = createSelector(
    selectShiftSchedulerControl,
    (form) => form.controls.startTime,
  );
  const selectEndTimeFormControl = createSelector(
    selectShiftSchedulerControl,
    (form) => form.controls.startTime,
  );
  const selectStartTimeIsValid = createSelector(
    selectStartTimeFormControl,
    (control) => control.isValid,
  );
  const selectEndTimeIsValid = createSelector(
    selectEndTimeFormControl,
    (control) => control.isValid,
  );
  const selectListingPeriodIsValid = createSelector(
    selectStartTimeIsValid,
    selectEndTimeIsValid,
    (startIsValid, endIsValid) => startIsValid && endIsValid,
  );
  const selectCostCentreNumberValue = createSelector(
    selectShiftSchedulerControl,
    (form) => form.value.costCentreNumber,
  );

  const selectConsentBackdatedShiftsControl = createSelector(
    selectShiftSchedulerControl,
    (form) => form.controls.consentBackdatedShifts,
  );

  const selectShiftSchedulerControlIsInvalid = createSelector(
    selectShiftSchedulerControl,
    (control) => control.isInvalid,
  );

  const selectStartTimeValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.startTime,
  );

  const selectEndTimeValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.endTime,
  );

  const selectIsEmploymentPeriodInPast = createSelector(selectStartTimeValue, (startTime) =>
    fromForm.isEmploymentPeriodInThePast(startTime),
  );

  const selectIsRepeating = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.isRepeating,
  );

  const selectRepetitionDatesValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.repetitionDates,
  );

  const selectRemainingPositionsToFill = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.remainingPositionsToFill,
  );
  const selectTimeFragmentsControl = createSelector(
    selectShiftSchedulerControl,
    (state) => state.controls.timeFragments,
  );

  const selectIsRepeatingDateInPast = createSelector(
    selectRepetitionDatesValue,
    selectIsRepeating,
    selectStartTimeValue,
    (repetitionDates, isRepeating, startTime) =>
      fromForm.isRepeatingDateInPast(repetitionDates, isRepeating, startTime),
  );

  const selectJobListingFormStartTimeValue = createSelector(selectStartTimeValue, (startTimeStr) =>
    Time.getMoment(startTimeStr).toDate(),
  );
  const selectJobListingFormEndTimeValue = createSelector(selectEndTimeValue, (endTimeStr) =>
    Time.getMoment(endTimeStr).toDate(),
  );

  const selectExtendedFormGradesArray = createSelector(
    selectExtendedJobListingFormWizardState,
    (form) => form.controls.gradesSection.controls.grades,
  );

  const selectStartEndRepetition = createSelector(
    selectStartTimeValue,
    selectEndTimeValue,
    selectRepetitionDatesValue,
    (startTime, endTime, repetitionDates) => {
      const formatRepetitionDates = unbox(repetitionDates).map((d) =>
        Time.getTimeFromDateAndSetToOtherDate(startTime, d).toISOString(),
      );
      return { startTime, endTime, repetitionDates: box(formatRepetitionDates) };
    },
  );

  const selectJobListingNotesControl = createSelector(
    selectShiftCreationFormControl,
    (state) => state.controls.jobListingNotes,
  );
  const selectListingFormUDP = createSelector(
    selectJobListingFormWizardState,
    (state) => state.userDefinedProperties as IJobListingFormUserDefinedProperties,
  );
  const selectUseRateCardRates = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.useRateCardRates,
  );
  const selectSpecialtyAndPeriodAreValid = createSelector(
    selectJobListingFormPrimaryProfessionSpecialtyValue,
    selectListingPeriodIsValid,
    (jobListingFormPrimaryProfessionSpecialtyValue, listingPeriodIsValid) =>
      jobListingFormPrimaryProfessionSpecialtyValue && listingPeriodIsValid,
  );
  const selectCanCalculateApprovedRates = createSelector(
    selectUseRateCardRates,
    selectSpecialtyAndPeriodAreValid,
    (useRateCardRates, specialtyAndPeriodAreValid) =>
      useRateCardRates && specialtyAndPeriodAreValid,
  );

  const selectRateCardRatesRecalculated = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.rateCardRatesRecalculated,
  );
  const selectCanCalculateApprovedRatesWithUpdate = (props: {
    professionSpecialty: number;
    startTime: Date;
    endTime: Date;
    nonResidentOnCall: boolean;
  }) =>
    createSelector(
      selectRateCardRatesRecalculated,
      selectCanCalculateApprovedRates,
      selectJobListingFormPrimaryProfessionSpecialtyValue,
      selectJobListingFormNonResidentOnCallValue,
      selectJobListingFormStartTimeValue,
      selectJobListingFormEndTimeValue,
      (
        rateCardRatesRecalculated,
        canCalculateApprovedRates,
        professionSpecialty,
        nonResidentOnCall,
        startTime,
        endTime,
      ) =>
        canCalculateApprovedRates &&
        (rateCardRatesRecalculated ||
          professionSpecialty !== props.professionSpecialty ||
          nonResidentOnCall !== props.nonResidentOnCall ||
          +startTime !== +props.startTime ||
          +endTime !== +props.endTime),
    );
  const selectRateEditingDisabled = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.rateEditingDisabled,
  );
  const selectRateCardRateListings = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.rateCardRateListings,
  );
  const selectRateCardGrades = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.rateCardRateGrades,
  );
  const selectGradesWithDifferentBreakDownExist = createSelector(
    selectListingFormUDP,
    (userDefinedProperties) => userDefinedProperties.gradesWithDifferentBreakDownExist,
  );

  return {
    selectJobListingFormWardValue,
    selectJobListingFormRosterValue,
    selectJobListingFormPrimaryProfessionSpecialtyValue,
    selectCostCentreNumberValue,
    selectJobListingFormProfessionSpecialtyValues,
    selectJobListingFormProfessionValue,
    selectJobListingFormNonResidentOnCallValue,
    selectJobListingFormNotesValue,
    selectJobListingFormDemandNotesValue,
    selectCopyNoteAcrossRepetitionDateFormValue,
    selectJobListingFormSiteValue,
    selectJobListingFormCostCentreNumberValue,
    selectRepetitionDatesValue,
    selectJobListingFormId,
    selectJobListingFormGradeValues,
    selectExtendedJobListingFormWizardState,
    selectJobListingFormVacancyReason,
    selectJobListingFromForm,
    selectIsSectionDetailsInvalid,
    selectIsSectionDescriptionInvalid,
    selectIsSectionGradesInvalid,
    selectIsFormInvalid,
    selectRemainingPositionsToFill,
    selectIsExternalJobListingMode,
    selectJobListingGradeControl,
    selectJobListingFormWizardState,
    selectLocalJobListingFromForm,
    selectEnabledJobListingGrades,
    selectIsEmploymentPeriodInPast,
    selectIsRepeatingDateInPast,
    selectJobListingFormStartTimeValue,
    selectShiftCreationControlIsInvalid,
    selectShiftSchedulerControlIsInvalid,
    selectGradesSectionControlIsInvalid,
    selectConsentBackdatedShiftsControl,
    selectShiftSchedulerControl,
    selectTimeFragmentsControl,
    selectExtendedFormGradesArray,
    selectRateViolationReasonControl,
    selectRateViolationReasonValue,
    selectStartEndRepetition,
    selectJobListingNotesControl,
    selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
    selectJobListingFormCrossCoveringProfessions,
    selectIsCrossCoveringForm,
    selectJobListingGradesAreValid,
    selectProfessionGradesAreValid,
    selectUseRateCardRates,
    selectRateEditingDisabled,
    selectRateCardRateListings,
    selectRateCardGrades,
    selectInsufficientRateCardConfigurationError,
    selectListingPeriodIsValid,
    selectStartTimeIsValid,
    selectEndTimeIsValid,
    selectEndTimeFormControl,
    selectStartTimeFormControl,
    selectCanCalculateApprovedRates,
    selectSpecialtyAndPeriodAreValid,
    selectCanCalculateApprovedRatesWithUpdate,
    selectGradesWithDifferentBreakDownExist,
    selectRateCardRatesRecalculated,
    selectEnabledJobListingGradeIds,
  };
}
