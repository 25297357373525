import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, index } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IUniqueIndexField } from '@locumsnest/core/src/lib/ngrx/interfaces';

import { IDemandRequestEntity } from './interfaces';

export const LISTING_INDEX: IUniqueIndexField = {
  fieldName: 'jobListing',
  unique: true,
  reAssignMent: false,
};
const indexEntity = index([LISTING_INDEX]);

const namespace = 'DemandRequest';
const adapter = createEntityAdapter<IDemandRequestEntity>({}, indexEntity);
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
);
export { adapter, initialState, paginationAdapter };
